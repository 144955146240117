h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: bold !important;
  margin-bottom: 1.5rem !important;
  font-family: "Aller" !important;
}

h1,
.h1 {
  font-size: 3.125rem !important;
  line-height: 3.1875rem !important;
  font-family: "AllerBold" !important;
}

h2,
.h2 {
  font-size: 2.5rem !important;
  line-height: 2.8125rem !important;
  font-family: "AllerBold" !important;
}

h3,
.h3 {
  font-size: 2.1875rem !important;
  line-height: 2.8125rem !important;
  font-family: "AllerBold" !important;
}

body,
p,
table,
label,
.list {
  font-family: "Aller";
  font-size: 1.375rem;
}

a {
  text-decoration: none;
  color: #f8b42d !important;
}

a:hover {
  text-decoration: underline;
}

.zitelia-header {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: "white";
  -webkit-box-shadow: 2px 8px 24px -3px rgba(150, 150, 150, 0.83);
  box-shadow: 2px 8px 24px -3px rgba(150, 150, 150, 0.83);
}

.zitelia-header-box {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.zitelia-header .container .zitelia-header-link {
  width: 12.5rem;
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.zitelia-header .container .zitelia-header-link .zitelia-logo-container {
  width: 30.5rem;
}

.zitelia-header
  .container
  .zitelia-header-link
  .zitelia-logo-container
  > svg
  > path {
  stroke: #f8b42d;
  fill: #f8b42d;
}

.zitelia-header .menu {
  margin-top: 0 !important;
}

.zitelia-header .menu .item {
  font-size: 1rem;
}

.zitelia-header .menu .item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.zitelia-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 10rem;
  background-color: #f8b42d;
  color: black;
}

.normal-weight-label {
  font-weight: normal;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 45%;
}

.margin-1 {
  margin: 0.5rem;
}

body > div.ui.page.modals.dimmer.transition.visible.active > div {
  left: auto !important;
}
input[type="date" i] {
  padding-inline-start: 0px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0;
  padding: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  transform: translate(204px, 0px) !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: black !important;
}

.footer-link {
  color: black !important;
  text-decoration: underline;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 560px) {
  .image-container {
    box-shadow: 2px 0px 10px -1px rgb(150 150 150 / 83%);
  }
}

@media (max-width: 768px) {
  .field {
    margin-bottom: 1rem !important;
  }
}
